<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>
    <v-row justify="center">
      <v-card class="mx-auto" v-if="!loadingFetch">
        <v-card-text>
          <v-container fluid>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-row class="d-flex justify-end align-center mr-1 ml-1">
                  <v-col cols="12" md="2" class="mt-8">
                    <v-select
                      outlined
                      v-model="product.type"
                      label="Tipo"
                      :items="['Movel', 'Imovel', 'Automovel']"
                      required
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2" :class="isMobile ? 'mt-n4' : 'mt-8'">
                    <v-text-field
                      outlined
                      type="number"
                      v-model="product.inventory"
                      label="QTD Inventario"
                      required
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="4" class="mt-n5">
                    <span>Valor UN Compra</span>
                    <money
                      class="money"
                      v-model="product.purchasePrice"
                      v-bind="money"
                    ></money>
                  </v-col>

                  <v-col cols="6" md="4" class="mt-n5">
                    <span>Valor UN Inventário</span>
                    <money
                      class="money"
                      v-model="product.inventoryPrice"
                      v-bind="money"
                    ></money>
                  </v-col>
                </v-row>
                <v-col cols="12" md="8" :class="isMobile ? 'mt-1' : 'mt-n8'">
                  <v-text-field
                    outlined
                    v-model="product.name"
                    label="Nome"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="product.registrationInternal"
                    label="Registro Interno"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="product.department"
                    label="Departamento"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="product.depositary"
                    label="Gestor"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="mt-n8">
                  <v-autocomplete
                    outlined
                    :items="categories"
                    v-model="product.supplierId"
                    label="Fornecedor"
                    item-text="person.corporateName"
                    item-value="id"
                    :loading="loading"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="product.brand"
                           :label="
                      product.type === 'Automovel'
                        ? 'Fabricante'
                        : product.type === 'Imovel'
                        ? 'Área Construida'
                        : 'Marca'
                    "
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="product.model"
                 :label="
                      product.type === 'Automovel'
                        ? 'Modelo/Ano'
                        : product.type === 'Imovel'
                        ? 'Área Total'
                        : 'Modelo'
                    "
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="product.legalRecord"
                    :label="
                      product.type === 'Automovel'
                        ? 'Placa'
                        : product.type === 'Imovel'
                        ? 'Registro Cartório de Imóveis'
                        : 'Registro Externo'
                    "
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="product.legalRecordType"
                    :label="
                      product.type === 'Automovel'
                        ? 'UF de Registro'
                        : product.type === 'Imovel'
                        ? 'Nome do Cartório'
                        : 'Orgão de Registro'
                    "
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="product.serialNumber"
                    :label="
                      product.type === 'Automovel'
                        ? 'Renavan'
                        : product.type === 'Imovel'
                        ? 'Inscrição Municipal'
                        : 'Número de Série'
                    "
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-textarea
                    outlined
                    label="Descrição/Observações"
                    v-model="product.description"
                    rows="7"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" class="mt-n3">
                  <v-btn text @click.native="openFileDialog">
                    {{ fileName ? fileName : "Imagem" }}
                    <v-icon right dark>mdi-camera</v-icon>
                  </v-btn>
                  <input
                    ref="fileRef"
                    type="file"
                    id="file-upload"
                    style="display: none"
                    @change="handleFileUpload"
                  />
                  <v-img
                    height="180"
                    width="180"
                    :src="urlPreview"
                    @click.native="openFileDialog"
                  ></v-img>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-row class="d-flex justify-end">
                  <v-col cols="12" md="4">
                    <v-btn
                      block
                      dark
                      :disabled="!valid"
                      :loading="loading"
                      color="#2ca01c"
                      @click="validate()"
                    >
                      Salvar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn block outlined color="blue darken-1" text to="/assets">
                      Cancelar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-col cols="12" v-else>
        <v-card height="800">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
export default Vue.extend({
  name: "AssetForm",
  components: { Money, PageHeader },
  data: () => ({
    valid: true,
    title: "Cadastro de Ativo",
    visibleFields: true,
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Ativos",
        disabled: false,
        href: "/assets",
      },
      {
        text: "Cadastro",
        disabled: true,
        href: "/asset/create",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    products: [],
    categories: [],
    loadingFetch: false,
    productId: null,
    isMobile: false,
    file: "",
    fileName: "",
    urlPreview: require("@/assets/icons/icon_camera.png"),
    product: {
      name: "",
      description: "",
      supplierId: null,
      inventoryPrice: 0.0,
      imageUrl: null,
      type: "Movel",
      purchasePrice: 0.0,
      registrationInternal: "",
      legalRecord: "",
      legalRecordType: "",
      brand: "",
      bardCode: "",
      department: "",
      depositary: "",
      model: "",
      unity: "UN",
      inventory: 1,
    },
    search: "",
    loading: false,
    productId: "",
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    checkType() {
      if (this.product.type === "SERVICO") {
        this.product.stockControl = false;
        this.product.brand = null;
        this.product.model = null;
        this.visibleFields = false;
      } else {
        this.product.stockControl = true;
        this.product.brand = "";
        this.product.model = "";
        this.visibleFields = true;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].name;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    async getItem() {
      const productId = this.$route.params.id;
      if (productId) {
        this.loadingFetch = true;
        this.title = "Editar";
        this.menu[2].text = "Editar";
        await http.get(`assets/${productId}`).then(
          async (res) => {
            const { data } = await res;
            this.product = data;
            this.productId = data.id;
            this.urlPreview = data.imageUrl ?? this.urlPreview;
            this.loadingFetch = false;
          },
          () => {
            this.loadingFetch = false;
            this.snackbar.text = "Falha ao buscar o Ativo. Tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }
    },
    getCategories() {
      http.get("gerentor/suppliers").then((res) => {
        this.categories = res.data;
      });
    },

    async registerOrUpdate() {
      this.loading = true;
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("systemClientId", "pedbem");
        formData.append("companyId", "pedbem");
        formData.append("name", new Date().toString());
        formData.append("path", "erp/assets");
        formData.append("type", "image-product");
        formData.append("public", 1);
        await http.post("base/files", formData).then(
          (d) => {
            this.product.imageUrl = d.data.imageUrl;
          },
          () => {
            this.snackbar.text =
              "Houve um erro ao fazer upload da IMAGEM, tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }
      this.product.inventoryPrice;
      if (this.productId) {
        await http.put(`assets/${this.productId}`, this.product).then(
          () => {
            this.snackbar.text = "Ativo atualizado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/assets");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string" ? response?.data : response?.data?.title;
            this.snackbar.text = "Erro ao atualizar o o ativo: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      } else {
        await http.post("assets", this.product).then(
          () => {
            this.snackbar.text = "Ativo cadastrado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/assets");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string" ? response?.data : response?.data?.title;
            this.snackbar.text = "Erro ao cadastrar o ativo: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      }
      this.loading = false;
    },
  },
  async mounted() {
    this.isMobile = this.$vuetify.breakpoint.mobile;
    await this.getCategories();
    await this.getItem();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
/* {id: "134f0959-e847-412a-9d8b-45a52b44e1d5", createdAt: "2023-05-05T00:00:15.173Z",…}
active : true brand : "AOC" createdAt : "2023-05-05T00:00:15.173Z" createdBy :
"e2da9fa8-2516-4366-a323-ff8349b370bb" department : null depositary : null description :
"TV AOC EM USO NA SALA DAS CRIANÇAS" document : null id :
"134f0959-e847-412a-9d8b-45a52b44e1d5" imageUrl :
"https://pedbem-322201.appspot.com.storage.googleapis.com/erp%2Fassets%2F2d3b6d5b-7243-4872-970a-5a6cd1d832e2.image%2Fwebp"
inactivated : false inactivatedDate : null inactivatedJustification : null inventory : 1
inventoryPrice : "2800.00" legalRecord : null legalRecordType : null model : "50 POL" name
: "TV LED 50 POLEGADAS AOC" purchaseDate : null purchasePrice : "2800.00"
registrationInternal : null serialNumber : "JKHUHSU484851881" supplier : {id:
"75721407-cb0b-46fc-99b8-5c53d94f4c02", active: true, createdAt:
"2021-08-08T05:13:08.553Z",…} supplierId : "75721407-cb0b-46fc-99b8-5c53d94f4c02"
systemClientId : "pedbem" type : "Movel" updatedAt : "2023-05-05T00:12:52.000Z" updatedBy
: "e2da9fa8-2516-4366-a323-ff8349b370bb" */
